<template>
  <div class="multivariate-index">
    <div class="multivariate-head">
      <div class="head-item" v-for="(item, i) in headArr" :key="i">
        <img :src="item.img" alt="" />
        <div class="text-box">
          <div v-if="!item.bold" class="item-text">
            {{ item.memo1 }}
          </div>
          <div v-else class="item-text">
            <span>{{ item.memo1 }}</span>
            {{ item.measurement }}
          </div>
          <div class="item-text">
            {{ item.memo2 }}
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="multivariate-content">
      <div>
        <div class="index-title">
          <div>最新手机号检索</div>
          <!-- <span @click="goPath('/key/channel')">更多>></span> -->
        </div>
        <el-table
          :data="communityList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="220px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '6px 0' }"
        >
          <el-table-column
            prop="target_account"
            label="手机号"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="interface_state"
            label="状态"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="
                  items.hasOwnProperty(scope.row.interface_state) === true
                    ? items[scope.row.interface_state].type
                    : 'primary'
                "
              >
                {{
                  items.hasOwnProperty(scope.row.interface_state) === true
                    ? items[scope.row.interface_state].label
                    : '获取失败'
                }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column
            prop="create_time"
            label="创建时间"
          ></el-table-column>
        </el-table>
      </div>
      <div>
        <div class="index-title">
          <div>最新邮箱检索</div>
          <!-- <span @click="goPath('/key/channel')">更多>></span> -->
        </div>
        <el-table
          :data="communityArr"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="220px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '6px 0' }"
        >
          <el-table-column
            prop="target_account"
            label="邮箱"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="interface_state"
            label="状态"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="
                  items.hasOwnProperty(scope.row.interface_state) === true
                    ? items[scope.row.interface_state].type
                    : 'primary'
                "
              >
                {{
                  items.hasOwnProperty(scope.row.interface_state) === true
                    ? items[scope.row.interface_state].label
                    : '获取失败'
                }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column
            prop="create_time"
            label="创建时间"
          ></el-table-column>
        </el-table>
      </div>

      <div>
        <div class="index-title">
          <div>最新用户名检索</div>
          <!-- <span @click="goPath('/key/channel')">更多>></span> -->
        </div>
        <el-table
          :data="accountList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="220px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '6px 0' }"
        >
          <el-table-column
            prop="target_account"
            label="用户名"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="interface_state"
            label="状态"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="
                  items.hasOwnProperty(scope.row.interface_state) === true
                    ? items[scope.row.interface_state].type
                    : 'primary'
                "
              >
                {{
                  items.hasOwnProperty(scope.row.interface_state) === true
                    ? items[scope.row.interface_state].label
                    : '获取失败'
                }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column
            prop="create_time"
            label="创建时间"
          ></el-table-column>
        </el-table>
      </div>
      <!-- -->
    </div>
    <div class="multivariate-foot">
      <div>
        <div class="index-title">
          <div>近7天检索记录</div>
        </div>
        <div id="myChrt"></div>
      </div>
      <div>
        <div class="index-title">
          <div>积分变更记录</div>
          <!-- <span @click="goPath('/key/channel')">更多>></span> -->
        </div>
        <el-table
          :data="userList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          :height="windowsHeight - 610"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column
            prop="create_time"
            label="时间"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="change_type"
            label="类型"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.change_type == 0">管理员调整</span>
              <span v-else-if="scope.row.change_type == -1">查询扣除</span>
              <span v-else-if="scope.row.change_type == 1">
                查询预扣积分退回
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="change" label="积分变更"></el-table-column>
          <el-table-column prop="id" label="查询id"></el-table-column>
        </el-table>
        <pagination
          class="video-pagination-child"
          :total="total"
          :page-size="limit"
          :currentPage="current"
          :pagerCount="5"
          background
          :sizesArr="[20, 50, 100]"
          @currentChange="currentChange"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { statistics, taskStatistics, balanceChange } from '@/api/dashboard.js'
import { logList } from '@/api/history'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/i12.png') + '"',
      headArr: [
        {
          img: require('../../assets/imgs/t1.png'),
          memo1: '开通日期：2020-03-01',
          memo2: '结束日期：2020-03-01',
          bold: false
        },
        {
          img: require('../../assets/imgs/t2.png'),
          memo1: 0,
          memo2: '手机号搜索',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t3.png'),
          memo1: 0,
          memo2: '邮箱检索',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t4.png'),
          memo1: 0,
          memo2: '用户名检索',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t5.png'),
          memo1: '0',
          memo2: '昵称检索',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t6.png'),
          memo1: '0',
          memo2: '账户余额',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t7.png'),
          memo1: '0',
          memo2: '历史数据',
          bold: true,
          measurement: ''
        }
      ],
      items: {
        '2': {
          type: 'success',
          label: '处理完成'
        },
        '0': {
          type: 'warning',
          label: '处理中'
        }
      },
      communityList: [],
      communityArr: [],
      accountList: [],
      userList: [],
      // 当前页
      current: 1,
      // 每页记录数
      limit: 20,
      // 总记录数
      total: 0
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    },
    info() {
      return this.$store.state.user.userInfo
    }
  },
  created() {
    this.getlogList('phone').then(res => {
      this.communityList = res.data.data.task_list.map(item => {
        if (item.interface_state == 2 && item.state == 2) {
          item.stateName = '处理完成'
        } else {
          item.stateName = '处理中'
        }
        return item
      })
    })
    this.getlogList('email').then(res => {
      this.communityArr = res.data.data.task_list.map(item => {
        if (item.interface_state == 2 && item.state == 2) {
          item.stateName = '处理完成'
        } else {
          item.stateName = '处理中'
        }
        return item
      })
    })
    this.getlogList('uname').then(res => {
      this.accountList = res.data.data.task_list.map(item => {
        if (item.interface_state == 2 && item.state == 2) {
          item.stateName = '处理完成'
        } else {
          item.stateName = '处理中'
        }
        return item
      })
    }),
      this.getBalanceChange()
  },
  mounted() {
    // 获取头部6个数据
    this.getHead()

    let time1 = this.info ? '' : ''
    let time2 = this.info ? this.info.expire_time : ''
    this.headArr[0].memo1 = '开通日期：' + time1
    this.headArr[0].memo2 = '结束日期：' + time2

    //
    taskStatistics().then(res => {
      this.getEchartsLine(
        'myChrt',
        res.data.data.date_list,
        res.data.data.phone_cnt_list,
        res.data.data.email_cnt_list,
        res.data.data.uname_cnt_list,
        res.data.data.nickname_cnt_list
      )
    })
  },
  methods: {
    getBalanceChange() {
      balanceChange({ page_index: this.current, page_size: this.limit }).then(
        res => {
          if (res.status == 200) {
            this.total = res.data.data.count
            this.userList = res.data.data.task_list
          }
        }
      )
    },
    getlogList(type) {
      return new Promise((resolve, reject) => {
        let obj = {
          page_index: 1,
          page_size: 5,
          target_type: type
        }
        logList(obj).then(res => {
          if (res.status == 200) {
            resolve(res)
          }
        })
      })
    },
    goPath(path) {
      this.$router.push(path)
    },

    getHead() {
      statistics().then(res => {
        // this.headArr[0].memo1 =
        //   '开通日期：' + this.$store.state.user.userInfo.create_time
        // this.headArr[0].memo2 =
        //   '结束日期：' + this.$store.state.user.userInfo.endTime
        this.headArr[1].memo1 = res.data.data.phone_task_count
        this.headArr[2].memo1 = res.data.data.email_task_count
        this.headArr[3].memo1 = res.data.data.uname_task_count
        this.headArr[4].memo1 = res.data.data.nickname_task_count
        this.headArr[5].memo1 = res.data.data.balance
        this.headArr[6].memo1 = res.data.data.history_count
      })
    },

    getEchartsLine(dom, dataX, dataY1, dataY2, dataY3, dataY4) {
      const chart = echarts.init(document.getElementById(dom))
      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['手机号', '邮箱', '用户名', '昵称']
        },
        grid: {
          x: 50,
          y: 60,
          x2: 40,
          y2: 30,
          borderWidth: 10
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: dataX,
          boundaryGap: false
        },
        yAxis: {
          type: 'value'
        },

        series: [
          {
            name: '手机号',
            type: 'line',
            smooth: true,
            // stack: 'Total',
            // lineStyle: {
            //   width: '4',
            //   color: '#f2597f'
            // },
            // emphasis: {
            //   focus: 'series'
            // },
            data: dataY1
          },
          {
            name: '邮箱',
            type: 'line',
            smooth: true,
            // stack: 'Total',
            // areaStyle: {},
            // emphasis: {
            //   focus: 'series'
            // },
            data: dataY2
          },
          {
            name: '用户名',
            type: 'line',
            smooth: true,
            // stack: 'Total',
            // areaStyle: {},
            // emphasis: {
            //   focus: 'series'
            // },
            data: dataY3
          },
          {
            name: '昵称',
            type: 'line',
            smooth: true,
            // stack: 'Total',
            // areaStyle: {},
            // emphasis: {
            //   focus: 'series'
            // },
            data: dataY4
          }
        ]
      }
      window.onresize = function() {
        chart.resize()
      }
      chart.setOption(option)
    },
    currentChange(val) {
      this.current = val
      this.getBalanceChange()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getBalanceChange()
    }
  }
}
</script>

<style lang="scss" scoped>
.multivariate-index {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px 20px;
  background: rgb(240, 242, 245);

  .multivariate-head {
    width: 100%;
    height: 131px;
    background: white;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    .head-item {
      width: calc(100% / 7 - 1px);
      height: 131px;
      border-right: 1px solid #f2f4f8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      &:nth-last-child(1) {
        border-right: none;
      }
      img {
        height: auto;
        object-fit: cover;
        align-self: center;
      }
      .text-box {
        align-self: center;
        margin-top: 15px;
        text-align: center;
        .item-text {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 22px;
          }
        }
      }
    }
  }
  .multivariate-content {
    height: 281px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    > div {
      width: 33%;
      height: 100%;
      background: #ffffff;
      border-radius: 5px;
      padding: 5px;
      overflow: hidden;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
  }
  .multivariate-foot {
    width: 100%;
    height: calc(100% - 440px);
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(50% - 5px);
      height: 100%;
      background: white;
      border-radius: 5px;
      overflow: hidden;
      padding: 5px;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
    #myChrt {
      width: 100%;
      height: calc(100% - 40px);
    }
  }

  .index-title {
    display: flex;
    justify-content: space-between;
    text-indent: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 24px;
    border-bottom: 1px solid #f4f4f4;
    padding: 5px 0;
    font-weight: 600;
    span {
      color: #7c7b7b;
      cursor: pointer;
      &:hover {
        color: rgb(11, 106, 214);
      }
    }
  }
  // ::v-deep .el-table__header .el-table__cell {
  //   background: rgb(243, 247, 249);
  // }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 0;
    height: 35px;
  }
  .table-cell-img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    vertical-align: middle;
  }
}
</style>
