import request from '@/plugins/axios'

// 首页头部数据
export function statistics() {
  return request({
    url: `/api/v1/dashboard/summary`,
    method: 'get'
  })
}
// 近7天
export function taskStatistics() {
  return request({
    url: '/api/v1/dashboard/task_statistics',
    method: 'get'
  })
}

// export function siteConfig(page, limit) {
//   return request({
//     url: `/web/site/config/${page}/${limit}`,
//     method: 'post'
//   })
// }

export function balanceChange(data) {
  return request({
    url: `/api/v1/account/balance_change_logs`,
    method: 'get',
    params: data
  })
}
